import { SET_SELECTED_VENDOR, RESET_VENDOR } from '../actions/vendorActions';

const initialVendorState = {
  selectedVendor: localStorage.getItem('vendorId') || null, // Load vendorId from localStorage
};

const vendorReducer = (state = initialVendorState, action) => {
  switch (action.type) {
    case SET_SELECTED_VENDOR:
      localStorage.setItem('vendorId', action.payload); // Store vendorId in localStorage
      return { ...state, selectedVendor: action.payload };

    case RESET_VENDOR: // Handle the RESET_VENDOR action
      localStorage.removeItem('vendorId'); // Remove vendorId from localStorage
      return { ...state, selectedVendor: null }; // Reset vendor in Redux state

    default:
      return state;
  }
};

export default vendorReducer;
