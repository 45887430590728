import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

// Lazy-loaded components
const Home = Loadable(lazy(() => import("./views/dashboard/Analytics")));
const CustomerList = Loadable(lazy(() => import("./views/pages/customers/CustomersList")));
const CategoryList = Loadable(lazy(() => import("./views/pages/categories/CategoryList")));
const VendorsList = Loadable(lazy(() => import("./views/pages/vendors/VendorsList")));
const ProductsList = Loadable(lazy(() => import("./views/pages/products/ProductsList")));
const OrdersList = Loadable(lazy(() => import("./views/pages/orders/OrdersList")));
const CreateVendor = Loadable(lazy(() => import("./views/pages/vendors/VendorCreateForm")));
const CreateCategory = Loadable(lazy(() => import("./views/pages/categories/CategoryCreateForm")));
const GroupList = Loadable(lazy(() => import("./views/pages/chat_notification/GroupList")));
const NotificationList = Loadable(lazy(() => import("./views/pages/chat_notification/NotificationList")));
const CreateProduct = Loadable(lazy(() => import("./views/pages/products/ProductCreateForm")));
const ViewProduct = Loadable(lazy(() => import("./views/pages/products/ViewProduct")));
const ViewProfile = Loadable(lazy(() => import("./views/pages/profile/ViewProfile")));
const EditProfile = Loadable(lazy(() => import("./views/pages/profile/EditProfile")));
const CartManage = Loadable(lazy(() => import("./views/pages/cart/CartManagement")));
const AppEchart = Loadable(lazy(() => import("./views/charts/echarts/AppEchart")));
const NotFound = Loadable(lazy(() => import("./views/pages/auth/NotFound")));
const JwtLogin = Loadable(lazy(() => import("./views/pages/auth/JwtLogin")));
const HandleGoogleRedirect = Loadable(lazy(() => import("./views/pages/products/HandleGoogleRedirect")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/dashboard", element: <Home /> },
      { path: "/profile/view", element: <ViewProfile /> },
      { path: "/profile/edit", element: <EditProfile /> },
      { 
        path: "/customers/list", 
        element: <AuthGuard auth={[authRoles.admin]}><CustomerList /></AuthGuard> 
      },
      { 
        path: "/categories/list", 
        element: <AuthGuard auth={[authRoles.admin, authRoles.vendor]}><CategoryList /></AuthGuard> 
      },
      { 
        path: "/vendors/list", 
        element: <AuthGuard auth={[authRoles.admin]}><VendorsList /></AuthGuard> 
      },
      { 
        path: "/products/list", 
        element: <AuthGuard auth={[authRoles.admin, authRoles.vendor]}><ProductsList /></AuthGuard> 
      },
      { 
        path: "/orders/list", 
        element: <AuthGuard auth={[authRoles.admin, authRoles.vendor]}><OrdersList /></AuthGuard> 
      },
      { 
        path: "/notification/list", 
        element: <AuthGuard auth={[authRoles.admin, authRoles.vendor]}><NotificationList /></AuthGuard> 
      },
      { 
        path: "/vendor/create", 
        element: <AuthGuard auth={[authRoles.admin]}><CreateVendor /></AuthGuard> 
      },
      { 
        path: "/category/create", 
        element: <AuthGuard auth={[authRoles.admin]}><CreateCategory /></AuthGuard> 
      },
      { 
        path: "/category/:mode/:id?", 
        element: <AuthGuard auth={[authRoles.admin]}><CreateCategory /></AuthGuard> 
      },
      { 
        path: "/vendor/:mode/:id?", 
        element: <AuthGuard auth={[authRoles.admin]}><CreateVendor /></AuthGuard> 
      },
      { 
        path: "/product/:mode/:id?", 
        element: <AuthGuard auth={[authRoles.admin, authRoles.vendor]}><CreateProduct /></AuthGuard> 
      },
      { 
        path: "/product/view/:id", 
        element: <AuthGuard auth={[authRoles.admin, authRoles.vendor]}><ViewProduct /></AuthGuard> 
      },
      { 
        path: "/group/list", 
        element: <AuthGuard auth={[authRoles.admin, authRoles.vendor]}><GroupList /></AuthGuard> 
      },
      { 
        path: "/cart/manage", 
        element: <AuthGuard auth={[authRoles.admin, authRoles.vendor]}><CartManage /></AuthGuard> 
      },
      {
        path: "/dashboard/default",
        element: <AuthGuard auth={[authRoles.admin]}><Home /></AuthGuard>,
      },
      {
        path: "/charts/echarts",
        element: <AuthGuard auth={[authRoles.editor]}><AppEchart /></AuthGuard>,
      },
    ],
  },
  { path: "/404", element: <NotFound /> },
  { path: "/signin", element: <JwtLogin /> },
  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "*", element: <Navigate to="/dashboard" /> },
  { path: "/oauth2callback", element: <HandleGoogleRedirect /> },
];

export default routes;