// src/redux/actions.js
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const login = (userData) => {
  return {
    type: LOGIN,
    payload: {
      user: userData.user,
      token: userData.token,
    },
  };
};

export const logout = () => ({
  type: LOGOUT,
});
