import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode' // import dependency
import { login } from '../../redux/actions/authActions';
import { setSelectedVendor } from '../../redux/actions/vendorActions'; // Adjust the path as necessary


const AuthGuard = ({ children, auth }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  // Check if user is in Redux; if not, check localStorage for token
  if (!user) {
    const token = localStorage.getItem('token');
    
    if (token) {
      try {
        // Decode the token to get user data
        const decodedToken = jwtDecode(token);

        dispatch(login({ user: decodedToken, token }));

        if (decodedToken.Vendor_id) {
          dispatch(setSelectedVendor(decodedToken.Vendor_id));
        }
      } catch (e) {
        console.error('Invalid token:', e);
        return <Navigate to="/signin" state={{ from: location }} />;
      }
    } else {
      return <Navigate to="/signin" state={{ from: location }} />;
    }
  }

  if (auth && !auth.includes(user?.role)) {
    return <Navigate to="/session/404" />;
  }

  return children;
};

export default AuthGuard;
